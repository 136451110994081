import {
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  onAuthStateChanged as _onAuthStateChanged,
} from "firebase/auth";

import { auth } from "./app";

export function onAuthStateChanged(cb) {
  return _onAuthStateChanged(auth, cb);
}

export async function signInWithEmailAndPassword(
  email: string,
  password: string,
) {
  await _signInWithEmailAndPassword(auth, email, password);
  try {
  } catch (error) {
    console.error("Error signing in", error);
  }
}

export async function createUserWithEmailAndPassword(
  email: string,
  password: string,
) {
  try {
    return _createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Error creating user", error);
  }
}

export async function signOut() {
  try {
    return auth.signOut();
  } catch (error) {
    console.error("Error signing out", error);
  }
}
