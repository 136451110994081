import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/indied.png";

const Loader = () => {
  const theme = useTheme();

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <img src={logo} alt="logo" />
    </Box>
  );
};

export default Loader;
