import { IndicatorEntry } from "./model/Table";

export const COMPETENCES = {
  CC: "Competencia ciudadana",
  CPSAA: "Competencia personal, social  y de aprender a aprender",
  CD: "Competencia digital",
  CCL: "Competencia comunicación lingüística",
  CE: "Competencia Emprendedora",
};

export const TYPES = {
  CONCEPTUALES: "Conceptuales",
  PROCEDIMENTALES: "Procedimentales",
  ACTITUDINALES: "Actitudinales",
};

export const RUBRICS = {
  0: "Relaciona las necesidades básicas sólo con aspectos vitales o materiales",
  1: "Acepta y reconoce, si se le explica, que otros aspectos no materiales (educación, sanidad, ejercicios de los derechos pueden ser importantes para la vida de las personas.",
  2: "Identifica explícitamente una vida digna no sólo con tener cubiertas las necesidades vitales, sino también con el acceso a los recursos y el ejercicio de los derechos.",
  3: "Entiende y argumenta la univerdalidad del derecho a tener cubiertas las necesidades básicas (vitales, acceso a los recursos, participación, reconocimiento, libertad) para una vida digna partiendo de la realidad de cada persona o cada sociedad.",
  4: "Asocia al desarrollo sólo al sistema económico y de producción.",
  5: "Atribuye al concepto de desarrollo características no sólo relacionado con aspectos económicos (acceso a la educación, a la sanidad..)",
  6: "Conoce la asistencia y el fundamento de diferentes modelos de desarrollo (capitalista, sostenible, buen vivir…)",
  7: "Identifica la relación entre los diferentes modelos de desarrollo y la calidad de vida",
  8: "Identifica los prinicipales retos actuales globales.",
  9: "Describe alguna características de los retos mundiales actuales y pone ejemplos que le resultan cercanos y/o que le ocurren a otras personas o grupos sociales en otros lugares del planeta.",
  10: "Relaciona los principales retos sociales (locales y/o mundiales) con las causas que lo originan, estableciendo sus consecuencias.",
  11: "Muestra una comprensión que se aproxima a una visión sistémica de estas problemáticas, relacionando situaciones de carácter local con problemas globales.",
  12: "Reconoce la existencia de problemas sociales y ambientales globales, percibiéndolos de manera aislada y desconectada entre sí.",
  13: "Identifica que los problemas socioambientales globales no afectan por igual a todas las personas o grupos, reconociendo algunas desigualdades relacionadas con el acceso a recursos, derechos o servicios básicos.",
  14: "Comienza a percibir cómo las desigualdades sociales globales están interrelacionadas con múltiples sistemas de opresión, como el racismo, el patriarcado, el capacitismo o el colonialismo.",
  15: "Integra una comprensión sistémica e interseccional de los problemas sociales globales, identificando cómo las dimensiones de género, raza, clase, discapacidad y otras intersectan para generar desigualdades complejas y multinivel.",
  16: "Tiene alguna referencia acerca del fenómeno de la globalización",
  17: "Identifica diversos enfloques para definir el fenómeno de la globalización",
  18: "Describe las implicaciones y efectos de la globalización reconociendo los aspectos positivos y negativos para la vida de las personas",
  19: "Reconoce que los efectos de la globalización son variables en función de diferentes factores (lugar, clase social, etc.)",
  20: "Tiene alguna referencia acerca de la Declaración Universal de los Derechos Humanos",
  21: "Explica la importancia de los DDHH y pone algún ejemplo de situaciones en las que no se cumplen.",
  22: "Reconoce la mayor vulnerabilidad de algunos colectivos y de algunas regiones del planeta en el cumplimiento de los Derechis Humanos",
  23: "Relaciona los principales problemas sociales (locales y/o mundiales) con el incumplimiento de los Derechos Humanos y los identifica con cuestiones económicas y políticas.",
  24: "No conoce diferencias culturales o sólo hace alusión a cuestiones del aspecto físico",
  25: "Identifica la diversidad cultural existente en nuestra sociedad, atendiendo a aspectos como el lenguaje, la religión, las costumbres cotidianas, la música, etc.",
  26: "Conoce la coexistencia de diversas culturas en un determinado territorio entendiendo el reconocimiento del otro como distinto (multiculturalidad)",
  27: "Reconoce el establecimiento de relaciones igualitarias entre las diferentes culturas como base de una convivencia intercultural",
  28: "Considera la identidad cultural de forma excluyente, entendiendo la diversidad cultural como algo ajeno.",
  29: "Reconoce que la identidad cultural puede contener múltiples culturas, pero percibe estas culturas como entidades separadas que no cambian ni enriquecen la identidad común.",
  30: "Percibe la identidad cultural como un espacio inclusivo donde las diversas culturas contribuyen a su riqueza, aunque aún ve a estas culturas como grupos con identidades propias dentro de la identidad compartida, sin una completa fusión o interrelación.",
  31: "Percibe y promueve la idea de una identidad cultural común en la que todas las culturas, incluyendo la propia, se entrelazan y enriquecen mutuamente.",
  32: "Limita las desigualdades de genero a las diferencias físicas, con cierta confusión entre género y sexo.",
  33: "Reconoce la existencia de presiones sociales para orientar las formas de vida en función de lo que se espera de hombres y mujeres",
  34: "Identifica situaciones que limitan y condicionan la vida de las personas en función de la identidad de género.",
  35: "Reconoce que las desigualdades por cuestión de género implica la vulneración de derechos y que éstas son construidas socialmente fruto de un sistema machista y patriarcal.",
  36: "Acepta o reproduce estereotipos raciales sin cuestionarlos.",
  37: "Reconoce que existen desigualdades hacia personas de distintas etnias o culturas, pero las percibe como casos aislados o justificados por diferencias culturales.",
  38: "Reflexiona sobre cómo estas desigualdades y afectan a las oportunidades de las personas racializadas, comenzando a cuestionar los prejuicios que las perpetúan.",
  39: "Demuestra una comprensión profunda de cómo el racismo vulnera los derechos humanos y afecta tanto a nivel individual como estructural, relacionando las desigualdades raciales con dinámicas económicas, políticas y culturales globales, como el racismo ambiental o la discriminación en políticas migratorias.",
  40: "Percibe el colonialismo como un fenómeno histórico sin relación con problemáticas actuales",
  41: "Identifica cómo la explotación de recursos naturales y humanos en el pasado colonial continúa influyendo en la desigualdad entre países y regiones.",
  42: "Analiza cómo las dinámicas coloniales históricas están presentes en el neocolonialismo actual, relacionando estas dinámicas con problemáticas como la desigualdad económica o la explotación de trabajadores y recursos.",
  43: "Comprende de forma sistémica cómo el colonialismo (histórico y actual) contribuye a perpetuar desigualdades globales",
  44: "Reconoce la existencia de situaciones discriminatorias hacia personas con discapacidad, justificándolas como hechos individuales y aislados, sin conectarlas con sistemas sociales o estructuras más amplias.",
  45: "Identifica que las barreras a las que se enfrentan las personas con discapacidad no solo son físicas o individuales, sino también sociales, económicas y culturales.",
  46: "Analiza cómo las barreras capacitistas están integradas en las instituciones, políticas públicas y prácticas culturales, limitando las oportunidades y los derechos de las personas con discapacidad.",
  47: "Comprende que el capacitismo es un sistema estructural que prioriza ciertas capacidades por encima de otras, perpetuando desigualdades.",
  48: "Entiende a participación ciudadana como la obligación de cumplir las normas.",
  49: "Considera la participación ciudadana como el ejercicio del derecho al voto y la respuesta a las propuestas y requerimiento de quienes gobiernan.",
  50: "Entiende la participación ciudadana como la implicación voluntaria de las personas en contextos sociales.",
  51: "Identifica la participación como un derecho y una obligación de todos los ciudadanos y ciudadanas que les da responsabilidad y poder para la construcción de un mundo más justo y equitativo",
  52: "Identifica que una persona responsable es la que cumple sus derechos y deberes",
  53: "Identifica a una persona responsable como la que se preocupa por las personas y el medioambiente de su entorno",
  54: "Asume y defiende que una persona responsable tiene que implicarse de forma concreta en ayudar a quienes lo necesitan, poniendo ejemplos de cómo hacerlo.",
  55: "Entiende que una persona responsable tiene un compromiso con la sociedad y el desarrollo sostenible, identificando la interrelación de sus actos con los problemas globales.",
  56: "Relaciona el desarrollo sostenible con el medioambiente sin considerar las otras dimensiones",
  57: "Conoce el concepto de desarrollo sostenible y sus tres dimensiones principales (social, económica y medioambiental). Aunque le cuesta relacionarlas con ejemplos concretos.",
  58: "Comprende el concepto de desarrollo sostenible de manera integral, incluyendo cómo las dimensiones social, económica y medioambiental están interrelacionadas. Puede explicar ejemplos y actuales.",
  59: "Relaciona el desarrollo sostenible con problemáticas complejas y los retos globales, relacionándolo con los 17 ODS de la Agenda 2030 y entendiendo la responsabilidad de personas, gobiernos e instituciones para alcanzarlos",
  60: "Percibe los cuidados como actividades individuales o familiares, tradicionalmente asociadas a las mujeres, sin considerar su relevancia para la comunidad o el medio ambiente.",
  61: "Reconoce que los cuidados son fundamentales para el sostenimiento de la vida, las relaciones humanas y el medio ambiente, aunque aún no comprende plenamente su impacto en la sostenibilidad ni cuestiona las desigualdades de género en su distribución.",
  62: "Entiende que los cuidados son esenciales para el equilibrio social, ambiental y de género, comprende el concepto e implicaciones de la economía de los cuidados reflexiona críticamente sobre la división desigual de responsabilidades por estereotipos de género y muestra compromiso con prácticas sostenibles.",
  63: "Promueve activamente los cuidados como una práctica central para la justicia social, la igualdad de género y la sostenibilidad ambiental, integrándolos en su vida diaria y fomentando cambios en su entorno que contribuyen al Buen Vivir.",
  64: "Utiliza de manera informal o pasiva los medios de comunicación más habituales.",
  65: "Utiliza de manera intencionada, pero básica, los medios de comunicación más habituales cuando le interesa un tema.",
  66: "Identifica una variedad de fuentes y comprende cuándo y por qué elegir un tipo de medio",
  67: "Selecciona conscientemente entre distintos medios y fuentes, interesándose por su origen y explicando por qué una fuente es adecuada en función del tema y propósito de la investigación.",
  68: "Identifica las ideas principales sobre un tema concreto en un texto más amplio sin evaluar la confiabilidad de la información y aceptando cualquier fuente como válida.",
  69: "Reconoce que hay cuestiones que no tienen una respuesta única, diferenciando entre hecho y opinión, identificando algunas señales básicas de confiabilidad",
  70: "Evalúa con precisión fuentes conocidas y demuestra una comprensión moderada sobre cómo verificar la confiabilidad de fuentes menos familiares, diferenciando el contenido de los mensajes según el origen de los mismos.",
  71: "Analiza la información en relación a otras situaciones (políticas, económicas, sociales, medioambientales) que se están produciendo en esos momentos, a nivel local y global, realizando un análisis crítico de la calidad de la información (autoría, actualidad, objetividad, rigor, accesibilidad..) e intencionalidad del medio.",
  72: "Expresa su opinión",
  73: "Expresa su opinión explicando los motivos de la misma",
  74: "Explica los fundamentos de sus opiniones mostrando disposición a contrastarlas con otras personas.",
  75: "Expone sus propias ideas y argumentos interconectándolos con el contexto social, político y económico e integrando las opiniones que comparte con otras personas.",
  76: "Muestra interés y curiosidad por todo lo que sucede a su alrededor",
  77: "Se muestra abierto a buscar la solución de los problemas a través de diversos caminos",
  78: "Asocia diferentes ideas, situaciones de distintos contextos para dar respuesta a un problema que se le plantea",
  79: "Aporta frecuentemente ideas novedosas para afrontar cualquier situación nueva sin temor a equivocarse",
  80: "Escucha otras opiniones priorizando e imponiendo sus ideas frente a las del resto de personas",
  81: "Participa activamente en debates y coloquios respetando las reglas de la interacción, intervención y cortesía que las regulan, respetando las opiniones de los demás.",
  82: "Responde con calma y asertividad ante opiniones y argumentos con los que está en desacuerdo, poniendo en valor las aportaciones de las demás personas, destacando lo positivo de la variedad de opiniones como fuente de información y aprendizaje.",
  83: "Facilita y promueve la participación y el intercambio de opiniones con otras personas, interesándose y poniendo en relación los diferentes puntos de vista.",
  84: "Identifica problemas sociales de manera superficial sin explorar sus causas ni consecuencias de forma crítica. No considera las dimensiones de género en su análisis.",
  85: "Es capaz de conectar problemas locales y globales y comienza a considerar las dimensiones de género, aunque aún sin identificar factores estructurales",
  86: "Analiza de manera sistémica detallada las causas y consecuencias de las problemáticas, considerando factores globales y estructurales/sistémicoS e identificando desigualdades de género como parte integral del problema.",
  87: "Realiza un análisis profundo e interconectado de las problemáticas, causas y consecuencias desde un enfoque de derecho,s e identificando a titulares de responsabilidades y obligaciones. Integra el enfoque de género y propone soluciones inclusivas y sostenibles.",
  88: "Marca directrices a un grupo para lograr el objetivo",
  89: "Organiza y reparte de forma consensuada las tareas y funciones de los miembros de un grupo",
  90: "Apoya y motiva al grupo para el desarrollo de las tareas propuestas.",
  91: "Incorpora y resalta las opiniones de los miembros del grupo, poniendo en valor las capacidades de cada uno de sus miembros y lo que pueden aportar a la definición y desarrollo de una tarea común, favoreciendo las buenas relaciones entre sus integrantes.",
  92: "Evita los conflictos identificándolos como algo negativo.",
  93: "Afronta el conflicto como algo propio de las relaciones humanas, entendiendo que hay intentar evitarlo.",
  94: "Afronta el conflicto a través de la cooperación y el diálogo, valorando el aspecto positivo de los mismos como posibilidad de aprendizaje.",
  95: "Enfrenta un conflicto de manera asertiva negociando en base a los intereses de todas las personas implicadas y tratando de llegar a acuerdos en los aspectos fundamentales.",
  96: "Muestra, esporádicamente, interés por los problemas que tienen las personas de su entorno",
  97: "Plantea qué ayuda necesitarían las personas que viven en situaciones de dificultad sin que aparezcan, de forma especial, implicaciones personales.",
  98: "Se implica, desde sus posibilidades, en las posibles soluciones para mejorar la vida de las personas que sufren situaciones de dificultad.",
  99: "Manifiesta su corresposabilidad en provocar o mantener situaciones que afectan negativamente a otras personas, participando, desde sus posibilidades, en las soluciones.",
  100: "No se preocupa por el efecto de sus acciones en la sociedad y el medioambiente",
  101: "Se pregunta por el efecto de algunas de sus acciones en la sociedad y el medioambiente",
  102: "Identifica sus acciones que tienen un efecto negativo en la sociedad y el medioambiente",
  103: "Modifica sus acciones que tienen un efecto negativo en la sociedad y el medioambiente",
  104: "No se pone en el lugar de otra persona, ni muestra sensibilidad hacia sus sentimientos y necesidades.",
  105: 'Se pone en el lugar de otra persona solo en situaciones extremas (guerras, catástrofes naturales) y desde el sentimiento de la "pena".',
  106: "Se pone en el lugar de otra persona desde el reconocimiento de sus necesidades",
  107: "Es capaz de ponerse en el lugar de otra persona reconociéndola como titular de derechos",
  108: "Ayuda a los/as compañeros que lo necesitan cuando se le solicita, sin tomar iniciativa propia",
  109: "Identifica y se ofrece a ayudar en situaciones de dificultad a personas de su entorno (aula, centro, barrio, comunidad)",
  110: "Colabora en actividades y campañas de solidaridad que se promueven desde el centro en colaboración con otras organizaciones sociales",
  111: "Muestra una actitud proactiva para trabajar porque no se produzcan situaciones de injusticia social",
  112: "Considera la igualdad unicamente en relación con las características físicas de las personas.",
  113: "Considera la igualdad como el trato similar a todas las personas",
  114: "Valora la diversidad y las necesidades específicas de cada persona y/o colectivo, pero aún considera la igualdad en términos de acceso a los recursos",
  115: "Considera la igualdad en términos de derechos y equidad",
  116: "Rechaza la existencia de la violencia de género en el ámbito familiar y social",
  117: "Identifica y rechaza la existencia de violencia de género en casos de agresión física.",
  118: "Reflexiona y visibiliza críticamente las señales de violencia que permite en su entorno socia, grupos de pares y familiar y las relaciona con actitudes machistas (micromachismos).",
  119: "Denuncia las situaciones de violencia de género que identifica en su entorno y conoce algún recurso de protección y/o denunciaen caso de necesidad propia o de otra persona.",
  120: "Es consciente de que el hecho de nacer hombre p mujer condiciona la vida de las personas en nuestra sociedad",
  121: "Admite la asistencia de presiones sociales para orientar la multiplicidad de formas de vida en función de lo que se espera de hombres y mujeres.",
  122: "Considera que el hecho de ser mujer u hombre no debería condicionar la vida personal y/o profesional de las personas.",
  123: "Defiende la capacidad de las personas de elegir con libertad las características desju vida más allá de los mandatos de género.",
  124: "Cree que las mujeres son naturalmente mejores cuidadoras que los hombres y que cuidar es una función propia de las mujeres. No cuestiona esta idea ni reflexiona sobre las desigualdades que conlleva.",
  125: "Reconoce que tanto hombres como mujeres necesitan cuidados y están en posición de cuidar a otras personas. Valora positivamente la implicación en las tareas de cuidado como una forma de contribuir a la dinámica familiar, aunque no reflexiona críticamente sobre las desigualdades en su distribución.",
  126: "Identifica como injusto que el peso de los cuidados recaiga mayoritariamente sobre las mujeres. Considera que esta situación perpetúa desigualdades, y plantea acciones para redistribuir los cuidados desde el ámbito privado/personal e instituciones sociales.",
  127: "Considera que las desigualdades en la distribución de los cuidados están relacionadas con la socialización diferenciada de género desde la infancia y entiende cómo esta socialización limita las oportunidades de las mujeres en el ámbito laboral y político, y propone estrategias para transformar estas dinámicas desde un enfoque estructural.",
  128: "Considera que el cuidado de las personas y el planeta es importante en una sociedad desarrollada pero en aspectos de interés general (educación sanidad)",
  129: "Identifica con claridad la existencia de colectivos sociales con necesidades especiales de cuidado y acepta la existencia de acciones positivas hacia ellos.",
  130: "Considera que una de las funciones principales de las instituciones públicas es la de brindar cuidados y como la ausencia de políticas sociales afecta negativamente al nivel de bienestar de las personas",
  131: "Defiende que el cuidado de las personas y del planeta debe estar en el centro de las políticas publicas (no solo las sociales o medioambientales) y la necesidad de ampliar las coberturas de los servicios sociales y de protección del medioambiente para garantizar los derechos humanos",
  132: "Muestra disponibilidad para colaborar con otras personas o colectivos cuando se le pide",
  133: "Participa/se involucra activamente en iniciativas y acciones colectivas que se organizan para mejorar la vida en su entorno inmediato",
  134: "Identifica y propone actividades, ideas o medidas para abordar situaciones que afectan a otras personas o colectivos en su entorno.",
  135: "Emprende en colaboración grupal la planificación y desarrollo de intervenciones para enfrentar situaciones de injusticia socioambiental.",
  136: "Se preocupa principalmente por problemas que le afectan directamente a nivel personal o familiar. No reflexiona sobre las conexiones entre estos problemas y las desigualdades de género o el impacto ambiental.",
  137: "Se interesa por problemas que afectan a su entorno cercano (como su barrio o escuela) y muestra disposición para colaborar en su resolución. Comienza a reconocer la relación entre las desigualdades de género y las problemáticas ambientales, aunque de forma superficial.",
  138: "Se interesa por cómo problemas locales están conectados con dinámicas globales, incluyendo desigualdades de género y crisis ambientales. Muestra interés en entender cómo estas problemáticas afectan especialmente a las mujeres y comunidades vulnerables, incluso si no le impactan directamente.",
  139: "Se preocupa por la interconexión entre los problemas globales, locales, de género y ambientales y por la búsqueda de soluciones que contribuyan a la justicia global.",
  140: "Realiza las actividades y tareas que se le encomiendan",
  141: "Muestra explicítamente y de manera voluntaria su disponibilidad para implicarse en alguna actividad o tarea.",
  142: "Desde una actitud de disponibilidad, elige las actividades en las que quiere participar.",
  143: "Propone organiza y desarrolla actividades individuales o en grupo en pro de un objetivo establecido",
  144: "Cumple adecuadamente las tareas que se le proponen",
  145: "Asume tareas de manera individual y voluntaria cumpliendo lo acordado",
  146: "Trabaja en equipo asumiendo sus responsabilidades dentro del grupo, actuando con responsabilidad",
  147: "Participa en la planificación y reparto de tareas en colaboración con otros, mostrando proactividad y resposabilidad en el cumplimiento del objetivo común.",
};

export const INDICATORS: IndicatorEntry[] = [
  {
    id: 0,
    name: "Identifica las necesidades para una vida digna",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 0 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 1,
    name: "Reconoce diferentes modelos de desarrollo y su relación con la calidad de vida",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 1 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 2,
    name: "Comprende principales retos actuales globales sociales y medioambientales",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 2 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 3,
    name: "Comprensión de los problemas socioambientales globales desde una perspectiva interseccional",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 3 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 4,
    name: "Conoce el fenómeno de la globalización",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 4 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 5,
    name: "Conoce los DDHH y sus implicaciones",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 5 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 6,
    name: "Identifica las diferencias culturales existentes dentro de una sociedad y respecto a otras sociedades",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 6 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 7,
    name: "Percepción de la identidad cultural compartida",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 7 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 8,
    name: "Identifica de las desigualdades por cuestión de género",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 8 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 9,
    name: "Identifica las desigualdades por cuestión de raza",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 9 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 10,
    name: "Identifica las desigualdades por cuestiones coloniales",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 10 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 11,
    name: "Identifica las desigualdades por cuestiones capacitistas",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 11 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 12,
    name: "Participación ciudadana",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 12 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 13,
    name: "Reconoce las características de una persona socialmente responsable",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 13 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 14,
    name: "Conoce y comprende el concepto de desarrollo sostenible",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 14 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 15,
    name: "Conoce y comprende qué son 'los cuidados' de las personas y el planeta",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 15 + i],
      annotation: "",
    })),
    type: TYPES.CONCEPTUALES,
  },
  {
    id: 16,
    name: "Manejo de diferentes medios, fuentes e instrumentos para la búsqueda de información.",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA, COMPETENCES.CD],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 16 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 17,
    name: "Realización de análisis crítico de la información",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 17 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 18,
    name: "Argumentación de sus  propias ideas y criterios",
    competences: [COMPETENCES.CC, COMPETENCES.CCL],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 18 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 19,
    name: "Capacidad indagatoria-investigadora",
    competences: [COMPETENCES.CC, COMPETENCES.CE],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 19 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 20,
    name: "Gestión de las opiniones de otras personas y colectivos",
    competences: [COMPETENCES.CC, COMPETENCES.CCL],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 20 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 21,
    name: "Capacidad de analizar críticamente las causas y consecuencias de las problemáticas socioambientales",
    competences: [COMPETENCES.CC],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 21 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 22,
    name: "Capacidad de liderazgo",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA, COMPETENCES.CE],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 22 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 23,
    name: "Gestión de conflictos",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA, COMPETENCES.CE],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 23 + i],
      annotation: "",
    })),
    type: TYPES.PROCEDIMENTALES,
  },
  {
    id: 24,
    name: "Sensibilidad ante la realidad de otras personas",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 24 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 25,
    name: "Responsabilidad ante el efecto de sus acciones en la sociedad y el medioambiente",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 25 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 26,
    name: "Empatía",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 26 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 27,
    name: "Solidaridad",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 27 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 28,
    name: "Compromiso con la Igualdad",
    competences: [COMPETENCES.CC],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 28 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 29,
    name: "Compromiso contra la violencia de género",
    competences: [COMPETENCES.CC],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 29 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 30,
    name: "Cuestionamiento crítico de los estereotipos de género.",
    competences: [COMPETENCES.CC],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 30 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 31,
    name: "Corresponsabilidad en los cuidados (mujeres-hombres)",
    competences: [COMPETENCES.CC],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 31 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 32,
    name: "Corresponsabiliad en los cuidados de las personas y del planeta (personas instituciones)",
    competences: [COMPETENCES.CC],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 32 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 33,
    name: "Implicación en actividades y proyectos colectivos orientados al bien común",
    competences: [COMPETENCES.CC, COMPETENCES.CE],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 33 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 34,
    name: "Preocupación por problemas sociales y/o globales",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 34 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 35,
    name: "Proactividad para la participación",
    competences: [COMPETENCES.CC, COMPETENCES.CE],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 35 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
  {
    id: 36,
    name: "Responsabilidad hacia las tareas y compromisos asumidos",
    competences: [COMPETENCES.CC, COMPETENCES.CPSAA, COMPETENCES.CE],
    rubrics: Array.from({ length: 4 }).map((_, i) => ({
      id: i,
      name: RUBRICS[4 * 36 + i],
      annotation: "",
    })),
    type: TYPES.ACTITUDINALES,
  },
];
