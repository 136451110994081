import { useEffect, useState } from "react";
import {
  Alert,
  AppBar,
  Box,
  Drawer,
  IconButton,
  Snackbar,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../assets/indied.png";
import DrawerContent from "./DrawerContent";
import { Table } from "../lib/model/Table";
import useAuth from "../hooks/useAuth";
import { listenToTables } from "../lib/firebase/firestore";
import { UserDataContext } from "../context/userData";
import { Feedback } from "../lib/model/Feedback";
import { AppDataContext } from "../context/appData";
import { Navigate, Outlet } from "react-router-dom";

const DashboardLayout = ({
  isAllowed,
  redirectPath,
}: {
  isAllowed: boolean;
  redirectPath: string;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useAuth();
  const [tables, setTables] = useState<{ [key: string]: Table }>({});
  const [feedback, setFeedback] = useState<Feedback>({} as Feedback);

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (user) {
      listenToTables(user.uid, (tables) => {
        setTables(tables);
      });
    }
  }, [user]);

  const resetFeedback = () => {
    setFeedback((prev) => ({
      ...prev,
      message: "",
    }));
  };

  return isAllowed ? (
    <>
      <UserDataContext.Provider value={tables}>
        <AppDataContext.Provider value={{ feedback, setFeedback }}>
          <AppBar position="static">
            <Toolbar variant="dense">
              <Box paddingY={1} sx={{ flexGrow: 1 }}>
                <img src={logo} alt="logo" height={40} />
              </Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Drawer open={drawerOpen} onClose={toggleDrawer} anchor="right">
                <DrawerContent toggleDrawer={toggleDrawer} />
              </Drawer>
            </Toolbar>
          </AppBar>
          <Box>
            <Outlet />
          </Box>
        </AppDataContext.Provider>
      </UserDataContext.Provider>
      <Snackbar
        open={!!feedback.message}
        autoHideDuration={5000}
        onClose={resetFeedback}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={feedback?.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {feedback?.message}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

export default DashboardLayout;
