import { CustomError } from "../model/CustomError";
import { Table, Tables } from "../model/Table";
import { db } from "./app";
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from "firebase/firestore";

const COLLECTION = "userData";

export const getTables = async (id: string) => {
  const docRef = doc(db, COLLECTION, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().tables;
  } else {
    console.log("No such document!");
    return null;
  }
};

export const saveTable = async (id: string, table: Table) => {
  const docRef = doc(db, COLLECTION, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const tables: Tables = docSnap.data().tables || {};

    if (
      !tables[table.id] &&
      Object.values(tables).find((t) => t.name === table.name)
    ) {
      throw new CustomError("Ya existe una tabla con ese nombre");
    }

    tables[table.id] = table;

    updateDoc(docRef, { tables });
  } else {
    setDoc(docRef, {
      tables: {
        [table.id]: table,
      },
    });
  }
};

export const deleteTable = async (id: string, tableId: string) => {
  const docRef = doc(db, COLLECTION, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const tables = docSnap.data().tables as Table[];
    delete tables[tableId];

    updateDoc(docRef, { tables });
  }
};

export const listenToTables = (id: string, cb: (tables: Tables) => void) =>
  onSnapshot(doc(db, COLLECTION, id), (doc) =>
    cb((doc.data()?.tables || {}) as Tables),
  );
