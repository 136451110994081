import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  TableChartOutlined as TableIcon,
  Add as AddIcon,
  ExitToApp as ExitIcon,
} from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import { signOut } from "../lib/firebase/auth";
import { useNavigate } from "react-router-dom";

const DrawerContent = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = (path: string) => {
    navigate(path);
    toggleDrawer();
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      minWidth="200px"
      role="presentation"
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick("/")}>
            <ListItemIcon>
              <TableIcon sx={{ mr: 5 }} />
            </ListItemIcon>
            <ListItemText primary="Mis tablas" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick("/tabla/nueva")}>
            <ListItemIcon>
              <AddIcon sx={{ mr: 5 }} />
            </ListItemIcon>
            <ListItemText primary="Crear tabla" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleSignOut}>
            <ListItemIcon>
              <ExitIcon sx={{ mr: 5 }} />
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItemButton>
        </ListItem>
      </List>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        sx={{ textAlign: "center", p: 2 }}
      >
        <Typography>{user?.email}</Typography>
      </Box>
    </Box>
  );
};

export default DrawerContent;
