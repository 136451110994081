import ExcelJS from "exceljs";
import { Table } from "../model/Table";

export const exportTable = async (data: Table, cb?: Function) => {
  const excelBuffer = await generateExcel(data);

  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${data.name}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  if (cb) {
    cb();
  }
};

export const generateExcel = async (data: Table) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Indicators");

  // Add the header row
  const headerRow = worksheet.getRow(1);
  headerRow.values = [
    "INDICADOR",
    "COMPETENCIAS",
    ...Array(4).fill("RÚBRICAS"),
  ];

  const subHeaderRow = worksheet.getRow(2);
  subHeaderRow.values = ["", "", "Nivel 1", "Nivel 2", "Nivel 3", "Nivel 4"];

  // Apply bold and centered text style to headers
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });

  subHeaderRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });

  // Merge Rúbricas header cells
  worksheet.mergeCells(1, 3, 1, 6);

  // Set specific widths for each column
  // Nombre
  worksheet.getColumn(1).width = 40;
  // Competencias
  worksheet.getColumn(2).width = 60;
  // Rúbricas
  for (let i = 3; i <= 6; i++) {
    worksheet.getColumn(i).width = 60;
  }

  // Add data rows
  data.indicators.forEach((indicator) => {
    const competences = indicator.competences.join("\n");
    const rubrics = indicator.rubrics;

    const dataRow = worksheet.addRow([
      indicator.name,
      competences,
      ...rubrics.map((r) => r.name),
    ]);

    // Write annotations at the bottom of the corresponding rubric cells
    indicator.rubrics.forEach((rubric, index) => {
      // Get the corresponding rubric cell
      const cell = worksheet.getCell(dataRow.number, 3 + index);

      cell.value = {
        richText: [
          { text: cell.value?.toString() ?? "" },
          {
            text: rubric.annotation ? `\n\n${rubric.annotation}` : "",
            font: { bold: true },
          },
        ],
      };
    });
  });

  // Adjust row height and enable text wrapping
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.alignment = {
        wrapText: true,
        horizontal: "left",
        vertical: "middle",
      };
    });
  });

  // Create buffer for download
  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};
