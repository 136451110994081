export const getReadableFirebaseMessage = (message: string) => {
  switch (message) {
    case "auth/user-not-found":
    case "auth/wrong-password":
    case "auth/invalid-credential":
      return "Usuario o contraseña incorrectos";
    case "auth/email-already-in-use":
      return "El email ya está en uso";
    case "auth/weak-password":
      return "Contraseña demasiado débil";
    default:
      return null;
  }
};
