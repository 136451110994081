import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import useAuth from "./hooks/useAuth";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import DashboardLayout from "./components/DashboardLayout";
import DashboardPage from "./pages/DashboardPage";
import NewTablePage from "./pages/NewTablePage";
import TablePage from "./pages/TablePage";
import Loader from "./components/Loader";

function App() {
  const { user, loading } = useAuth();

  return loading ? (
    <Loader />
  ) : (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="registro" element={<RegisterPage />} />
      </Route>
      <Route
        element={<DashboardLayout isAllowed={!!user} redirectPath="login" />}
      >
        <Route path="/" element={<DashboardPage />} />
        <Route path="tabla/nueva" element={<NewTablePage />} />
        <Route path="tabla/:id" element={<TablePage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
