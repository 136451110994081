import { useContext, useState } from "react";
import { Add as AddIcon, MoreVert as MenuIcon } from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  Typography,
} from "@mui/material";

import TableMenu from "../components/TableMenu";
import { UserDataContext } from "../context/userData";
import { Table, Tables } from "../lib/model/Table";
import { deleteTable } from "../lib/firebase/firestore";
import useAuth from "../hooks/useAuth";
import { exportTable } from "../lib/helpers/dataExport";
import { useNavigate } from "react-router-dom";

const COLORS = [
  "#adb610",
  "#e5461b",
  "#0076bb",
  "#f59d00",
  "#432364",
  "#c41138",
];

const AppPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const tables: Tables = useContext(UserDataContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<null | Table>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Table,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    deleteTable(user!.uid, selectedItem!.id);
  };

  const handleExport = async () => {
    exportTable(selectedItem!, handleClose);
  };

  const handleAdd = () => {
    navigate("/tabla/nueva");
  };

  const navigateToTable = (id: string) => {
    navigate(`/tabla/${id}`);
  };

  const handleClone = () => {
    navigate("/tabla/nueva?clonar=" + selectedItem!.id);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 3, py: 2, backgroundColor: "#cfcfcf" }}
      >
        <Typography>Mis Tablas</Typography>
        {Object.entries(tables).length > 0 && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
            sx={{ backgroundColor: "#f59d00" }}
          >
            Añadir tabla
          </Button>
        )}
      </Box>
      {Object.entries(tables).length > 0 ? (
        <Box
          sx={{
            py: 2,
            px: 3,
          }}
        >
          <Grid container spacing={2}>
            {Object.entries(tables)?.map(([key, table], i) => (
              <Grid size={{ xs: 12, md: 4 }} key={key}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    backgroundColor: COLORS[i % COLORS.length],
                    ":hover": {
                      backgroundColor: alpha(COLORS[i % COLORS.length], 0.8),
                    },
                    px: 2,
                  }}
                >
                  <Box flexGrow={1}>
                    <Typography
                      role="button"
                      onClick={() => navigateToTable(table.id.toString())}
                      sx={{
                        py: 2,
                        ":hover": { cursor: "pointer" },
                        fontWeight: "bold",
                      }}
                      color="white"
                    >
                      {table.name}
                    </Typography>
                  </Box>
                  <IconButton onClick={(e) => handleClick(e, table)}>
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            py: 6,
            px: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
            sx={{ backgroundColor: "#f59d00" }}
          >
            Añadir tabla
          </Button>
        </Box>
      )}
      <TableMenu
        anchorEl={anchorEl}
        isOpen={isMenuOpen}
        onClose={handleClose}
        onDelete={handleDelete}
        onDownload={handleExport}
        onClone={handleClone}
      />
    </>
  );
};

export default AppPage;
