import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  cssVariables: true,
  palette: {
    mode: "light",
    primary: {
      main: "#03387a",
    },
    background: {
      default: "#03387a",
    },
    grey: {
      400: "#d2cdcc",
    },
  },
});

export default theme;
