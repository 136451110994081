import { Feedback } from "../lib/model/Feedback";
import { createContext } from "react";

type AppDataContextType = {
  feedback: Feedback;
  setFeedback: (feedback: Feedback) => void;
};

export const AppDataContext = createContext<AppDataContextType>(
  {} as AppDataContextType,
);
