import { createSvgIcon } from "@mui/material";

const ActitudinalIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 512 512"
    fill="currentColor"
    stroke="currentColor"
  >
    <path d="M2.179,253.821a253.233,253.233,0,0,1,14.252-84.056,8,8,0,1,1,15.1,5.3,238.818,238.818,0,0,0,5.05,170.647A8,8,0,1,1,21.822,351.9,252.3,252.3,0,0,1,2.179,253.821ZM335.021,478.2a238.806,238.806,0,0,1-170.908-4.959A8,8,0,0,0,157.926,488a254.811,254.811,0,0,0,182.409,5.295,8,8,0,0,0-5.314-15.092ZM484.607,162.918a8,8,0,0,0-4.812,10.238,238.878,238.878,0,0,1-2.228,167.259,8,8,0,1,0,14.9,5.828,254.873,254.873,0,0,0,2.378-178.513A8,8,0,0,0,484.607,162.918ZM172.307,31.142A238.488,238.488,0,0,1,368.807,44.4a8,8,0,0,0,7.6-14.08A254.495,254.495,0,0,0,166.676,16.165a8,8,0,0,0,5.631,14.977Zm-14.6,333.578a12.443,12.443,0,0,1-12.428-12.99,110.553,110.553,0,0,1,85.621-103,55.894,55.894,0,1,1,50.2,0,110.553,110.553,0,0,1,85.621,103,12.441,12.441,0,0,1-12.428,12.991ZM256,238.708a39.893,39.893,0,1,0-39.893-39.893A39.938,39.938,0,0,0,256,238.708ZM161.5,348.72h189a94.839,94.839,0,0,0-189,0ZM137.02,380.8V499.643a8,8,0,0,1-8,8H10.179a8,8,0,0,1-8-8V380.8a8,8,0,0,1,8-8H129.02A8,8,0,0,1,137.02,380.8Zm-16,8H18.179V491.643H121.02ZM508.749,134.443a8,8,0,0,1-6.928,12H351.2a8,8,0,0,1-6.927-12L419.582,4a8,8,0,0,1,13.856,0Zm-20.784-4L426.51,24,365.055,130.443Zm20.3,300.573a80.985,80.985,0,1,1-80.985-80.984A81.076,81.076,0,0,1,508.262,431.016Zm-16,0A64.985,64.985,0,1,0,427.277,496,65.058,65.058,0,0,0,492.262,431.016ZM6.179,134.423a8,8,0,0,1-4-6.928V48.061a8,8,0,0,1,4-6.928L74.972,1.414a8.005,8.005,0,0,1,8,0l68.793,39.719a8,8,0,0,1,4,6.928V127.5a8,8,0,0,1-4,6.928L82.972,174.141a8,8,0,0,1-8,0Zm12-11.547,60.793,35.1,60.793-35.1V52.68L78.972,17.58,18.179,52.68Z" />
  </svg>,
  "Actitudinal",
);

export default ActitudinalIcon;
