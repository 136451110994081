import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Delete as DeleteIcon,
  NoteAltOutlined as NoteAddIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Input,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";

import { IndicatorEntry, Rubric } from "../lib/model/Table";

const IndicatorCard = ({
  indicator,
  onDelete,
  onRubricAnnotationChange,
  closedInputs,
  onInputsClosed,
}: Readonly<{
  indicator: IndicatorEntry;
  onDelete: (indicator: IndicatorEntry) => void;
  onRubricAnnotationChange: (
    indicatorId: number,
    rubric: Rubric,
    value: string,
  ) => void;
  closedInputs: boolean;
  onInputsClosed: () => void;
}>) => {
  const theme = useTheme();
  const [rubricAnnotationsInputShowing, setRubricAnnotationsInputShowing] =
    useState<boolean[]>(indicator.rubrics.map(() => false));

  const toggleRubricAnnotationsInputShowing = (index: number) => {
    setRubricAnnotationsInputShowing((prev) => {
      const next = [...prev];
      next[index] = !next[index];
      return next;
    });
  };

  useEffect(() => {
    if (closedInputs) {
      setRubricAnnotationsInputShowing(indicator.rubrics.map(() => false));
      onInputsClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedInputs]);

  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">{indicator.name}</Typography>
          <IconButton onClick={() => onDelete(indicator)}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Typography variant="h6">Competencias</Typography>
        <List>
          {indicator.competences.map((competence) => (
            <ListItem key={competence}>{competence}</ListItem>
          ))}
        </List>
        <Typography variant="h6">Rúbricas</Typography>
        <List>
          {indicator.rubrics.map((rubric, index) => (
            <ListItem
              key={rubric.name}
              secondaryAction={
                rubricAnnotationsInputShowing[index] ? null : (
                  <IconButton
                    edge="end"
                    aria-label="anotate"
                    onClick={() => toggleRubricAnnotationsInputShowing(index)}
                  >
                    <NoteAddIcon />
                  </IconButton>
                )
              }
            >
              <Box display="flex" width="100%" alignItems="center">
                <Box display="flex" flexDirection="column" width="100%">
                  <Typography>{rubric.name}</Typography>
                  {rubricAnnotationsInputShowing[index] ? (
                    <Input
                      value={rubric.annotation ?? ""}
                      onChange={(e) =>
                        onRubricAnnotationChange(
                          indicator.id,
                          rubric,
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                  ) : (
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      {rubric.annotation}
                    </Typography>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default IndicatorCard;
