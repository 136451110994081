import { useEffect, useState } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import ConceptualIcon from "./ConceptualIcon";
import ActitudinalIcon from "./ActitudinalIcon";
import ProcedimentalIcon from "./ProcedimentalIcon";

import { INDICATORS, TYPES } from "../lib/data";
import { IndicatorEntry } from "../lib/model/Table";
const getStyles = (type: string) => {
  switch (type) {
    case TYPES.CONCEPTUALES:
      return { color: "#0076bb", icon: <ConceptualIcon /> };
    case TYPES.PROCEDIMENTALES:
      return { color: "#e5461b", icon: <ProcedimentalIcon /> };
    case TYPES.ACTITUDINALES:
      return { color: "#432364", icon: <ActitudinalIcon /> };
    default:
      return {};
  }
};

const IndicatorFilterList = ({
  onItemClick,
  onItemDelete,
  currentIndicatorIds,
  currentlySelectedIndicatorIds,
}: {
  onItemClick: (indicator: IndicatorEntry) => void;
  onItemDelete: (indicator: IndicatorEntry) => void;
  currentIndicatorIds: number[];
  currentlySelectedIndicatorIds: number[];
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleIndicators, setVisibleIndicators] = useState(
    INDICATORS.filter(
      (indicator) => !currentIndicatorIds.includes(indicator.id),
    ),
  );

  useEffect(() => {
    const filteredIndicators = INDICATORS.filter(
      (indicator) =>
        indicator.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !currentIndicatorIds.includes(indicator.id),
    );
    setVisibleIndicators(filteredIndicators);
  }, [searchTerm, currentIndicatorIds]);

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        label="Buscar"
        variant="filled"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <List>
        {visibleIndicators.map((indicator) => {
          const styles = getStyles(indicator.type);
          return (
            <ListItem
              key={indicator.id}
              secondaryAction={
                currentlySelectedIndicatorIds.includes(indicator.id) ? (
                  <IconButton onClick={() => onItemDelete(indicator)}>
                    <DeleteIcon />
                  </IconButton>
                ) : null
              }
              disablePadding
              sx={{
                backgroundColor: currentlySelectedIndicatorIds.includes(
                  indicator.id,
                )
                  ? "#d2cdcc"
                  : "inherit",
              }}
            >
              <ListItemButton
                role={undefined}
                onClick={() => onItemClick(indicator)}
              >
                <ListItemIcon sx={{ color: styles.color }}>
                  {styles.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ color: styles.color }}>
                      {indicator.name}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default IndicatorFilterList;
