import { Box, Typography } from "@mui/material";

const AuthCard = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX={1}
      paddingTop={1}
      paddingBottom={1}
    >
      {children}
      <Typography variant="caption" sx={{ mt: 2, textAlign: "center" }}>
        Indied ha sido creada por la{" "}
        <a href="https://www.madrecoraje.org/">Fundación Madre Coraje</a>
      </Typography>
    </Box>
  );
};

export default AuthCard;
